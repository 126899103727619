<template>
    <div class="sub-footer-bg bg-cover pt-5 py-lg-6 py-md-5">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-8 my-1 my-lg-0 text-center-res text-white">
                    <p class="m-0 fs-4 font-inter-light">Bank e Auctions India.com</p>
                    <p class="mb-3 fs-1 fs-lg-9 font-inter-bold">
                        Get More Features With Premium Membership
                    </p>
                    <p data-swiper-parallax="-400" class="fs--2 fs-lg-0">
                        Experience the unbelievable features exclusive only for premium membership like a dedicated
                        personalized dashboard, more months of archives, email alerts, premium access to
                        every e Auction with complete photos and videos of auctioned articles and legal advisory for the
                        auctioned items, etc...
                    </p>
                    <div data-swiper-parallax="-300" class="text-center-res mt-4">
                        <btn @click="$refs.popUpModal.show()" text="Register Now" color="" design="basic-b"
                             class="px-4 font-inter-medium lego-btn-transparent border-white"
                             size="md" borderRadius="0"/>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12 d-sm-none">
                    <div class="footer-side-image bg-cover">
                        <img src="../../assets/web/home/hand-and-phone-bank-e-auctions.png" alt="" class="footer-img">
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12 d-lg-none mt-5">
                    <div class="bg-cover w-100p fl-x fl-j-c">
                        <img src="../../assets/web/home/hand-and-phone-bank-e-auctions.png" alt="" class="w-50">
                    </div>
                </div>

            </div>

            <modal class="c-modal-1 no-close " :no-close-on-backdrop="true" title=""
                   ref="popUpModal" width="50r" header-color="">
                <div class="modal-sm-max-h-70vh thin-scrollbar">
                    <div class="text-center mb-3 fl-te-c mr-2 ml-2">
                        <div></div>
                        <img src="../../assets/img/logo/logo-1.png" :width="50" :height="30" alt="">
                        <i @click="$refs.popUpModal.close()" class="fa fa-close"/>
                    </div>

                    <h6 class="font-weight-bold text-center">Bank e Auctions India.com {{ selected }} Membership</h6>
                    <div class="px-3 m-w25 min-h-20re">
                        <div v-for="(feature, index) in features" :key="index" class="row p-2">
                            <div class="col-10">{{ feature }}</div>
                            <div class="col-2">
                                <div class="fl-x fl-j-c">
                                    <i class="fa fa-check text-secondary"/>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row m-0 mt-3 small font-weight-bold pb-3 px-2">
                        <div class="col-12 col-md-3 pr-md-0">
                            <div @click="setAccountDetails('Free')" v-bind:class="{ 'border-bg-8' : selected === 'Free'}"
                                 class="stat-card-min-h b-2 round-1 mt-3 mt-md-0 fl-x-cc p-2 cursor-pointer ">Free
                            </div>
                        </div>
                        <div class="col-12 col-md-3 pr-md-0">
                            <div @click="setAccountDetails('Premium')" v-bind:class="{ 'border-bg-8' : selected === 'Premium'}"
                                 class="stat-card-min-h b-2 round-1 mt-3 mt-md-0 fl-x-cc p-2 cursor-pointer">Premium<br/>₹ 3000.00/3 Months
                            </div>
                        </div>
                        <div class="col-12 col-md-3 pr-md-0">
                            <div @click="setAccountDetails('Gold')" v-bind:class="{ 'border-bg-8' : selected === 'Gold'}"
                                 class="stat-card-min-h b-2 round-1 mt-3 mt-md-0 fl-x-cc p-2 cursor-pointer">Gold<br/>₹ 5000.00/6 Months
                            </div>
                        </div>
                        <div class="col-12 col-md-3 pr-md-0">
                            <div @click="setAccountDetails('Platinum')"
                                 v-bind:class="{ 'border-bg-8' : selected === 'Platinum'}"
                                 class="stat-card-min-h b-2 round-1 mt-3 mt-md-0 fl-x-cc p-2 cursor-pointer">Platinum<br/>₹ 10000.00/Annum
                            </div>
                        </div>
                        <!--                <div @click="setAccountDetails('Free')" v-bind:class="{ 'border-bg-8' : selected === 'Free'}"-->
                        <!--                     class="col b-2 round-1 m-1 p-2 cursor-pointer">Free-->
                        <!--                </div>-->
                        <btn v-if="selected !== 'Free'" @click="buyPremium"
                             class="btn-block m-1 mt-3" color="secondary">
                            LOGIN TO UPGRADE<i class="fa fa-chevron-right ml-2"/></btn>
                        <btn v-else @click="registerFree" class="btn-block m-1 mt-3" color="secondary">
                            REGISTER<i class="fa fa-chevron-right ml-2"/></btn>
                    </div>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PremiumMembershipSection',

    data () {
        return {
            features: [],
            selected: ''
        };
    },

    mounted () {
        this.setAccountDetails('Premium');
    },

    methods: {
        buyPremium () {
            this.$refs.popUpModal.close();
            this.$router.push({ path: '/login/#' + this.selected });
        },
        registerFree () {
            this.$refs.popUpModal.close();
            this.$router.push({ path: '/register/' });
        },
        setAccountDetails (account) {
            this.selected = account;
            if (account === 'Premium') {
                this.features = [
                    '3 Months Premium Access',
                    'View All Auction Details ',
                    'Daily Email Alerts',
                    '6 Months of Archives',
                    'Dedicated personalized dashboard including bid history',
                    'Complete photos and videos related to Auctioned items',
                    'Legal advisory related to the auction property (2 nos)',
                    'Expert Consultation during property acquisition and price analysis '
                ];
            } else if (account === 'Gold') {
                this.features = [
                    '6 Months Premium Access',
                    'View All Auction Details ',
                    'Daily Email Alerts',
                    '1 Year of Archives',
                    'Dedicated personalized dashboard including bid history',
                    'Complete photos and videos related to Auctioned items',
                    'Legal advisory related to the auction property (5 nos)',
                    'Expert Consultation during property acquisition and price analysis '
                ];
            } else if (account === 'Platinum') {
                this.features = [
                    '12 Months Premium Access',
                    'View All Auction Details',
                    'Daily Email Alerts',
                    'Unlimited Years of Archives',
                    'Dedicated personalized dashboard including bid history',
                    'Complete photos and videos related to Auctioned items',
                    'Legal advisory related to the auction property (12 nos)',
                    'Expert Consultation during property acquisition and price analysis ',
                    'Dedicated Relationship Manager',
                    'Assistance for identification of the property till registration '

                ];
            } else if (account === 'Free') {
                this.features = [
                    'Daily Email Alerts',
                    'View Partial Auction Details'
                ];
            }
        }
    }
};
</script>

<style scoped lang="scss">
    .sub-footer-bg {
        background: url("../../assets/web/home/bg/bg-green.png");
        background-position: center;
        @media (min-width: 992px) {
            background-position: 100% 0%;

        }
    }

    .footer-side-image {
        img {
            width: 70%;
            height: auto;
            @media (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
            }
        }
    }

    .footer-side-image {
        /*content: "";*/
        position: absolute;
        //bottom: -0.375rem;
        bottom: -5.4rem;
        //bottom: 8.625rem;
        right: 0;
        /*background: url("../assets/web/home/footer-hand-and-phone.png");*/
        /*height: 100%;*/
        /*background-repeat: no-repeat;*/
        @media (min-width: 768px) and (max-width: 1024px) {
            bottom: -3.4rem;
        }

        img {
            width: 70%;
            height: auto;
            @media (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
            }
        }
    }
    .min-h-20re {
        min-height: 20rem !important;
    }
</style>
