<template>
    <div class="bg-inner bg-primary banner-contain pos-r pt-6 pt-md-0 py-lg-0"
         :class="{'about-banner': bannerType === 'about','contact-banner':bannerType==='contact'}"
         :style="banner">
        <div class="overlay-dark z-index--2"></div>
        <div class="fl-x-cc h-100 banner-content container z-index-99">
            <div class="text-center mt-3 px-lg-9">
                <div class="bottom-tag mb-4" v-if="heading !=='' || this.$slots.header">
                    <h5 class="font-inter-semi-bold fs-lg-5" v-if="heading !==''">{{ heading }}</h5>
                    <slot name="header" v-else></slot>
                </div>
                <div class="mt-4">
                    <p class="fs-lg-1" v-if="content !== ''">
                        {{ content }}
                    </p>
                    <slot name="content"></slot>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import bgImageSample from '../assets/img/banner/about-banner.jpg';

export default {
    name: 'InnerBanner',
    props: {
        details: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        bannerType: {
            type: String,
            default: ''
        },
        heading: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        }

    },
    data () {
        return {
            bgImageSample: bgImageSample
        };
    },
    computed: {
        banner () {
            if (this.image !== '') {
                return { backgroundImage: 'url(' + this.image + ')' };
            }
            return { backgroundImage: 'url(' + this.bgImageSample + ')' };
        }
    }
};
</script>

<style scoped lang="scss">
    .bg-inner {
        position: relative;
        background-size: cover;
        background-position: 60%;
        min-height: 20vh;
        @media (min-width: 992px) {
            min-height: 45vh;
        }
    }

    .about-banner {
        background-image: url('../assets/img/banner/about-banner.jpg');
    }

    .contact-banner {
        background-image: url('../assets/img/banner/contact-banner.jpg');
    }

    .banner-content {
        min-height: 20vh;
        @media (min-width: 992px) {
            min-height: 45vh;
        }
        z-index: 999;
    }

    .banner-contain {
        color: var(--color-white);
    }

    .overlay-dark {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.48);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 100px
    }

    .bottom-tag {
        &::after {
            content: '';
            position: absolute;
            margin-top: var(--spacer-2);
            width: 1.4rem;
            transform: translateX(-50%);
            height: 2px;
            //background-color: var(--color-white);
            background-color: var(--bottom-tag-color);
            //margin-top: 1rem;
        }
    }
</style>
