<template>
    <div>

        <section v-if="dataLoading" class="about py-lg-9">
            <div class="text-center pt-7 pb-7">
                <loading-animation class="text-primary text-5x"/>
            </div>
        </section>

        <div v-if="!dataLoading && details" class="about bg-4">

            <inner-banner heading="Contact Us"
                          banner-type="contact" :image="details.contact_us.banner_image"
                          :content="details.contact_us.banner_text_3">
            </inner-banner>

            <div class="container py-7">
                <div class="bg-white p-lg-5">
                    <div class="row">

                        <div class="col-12 col-lg-4 col-md-4 order-2 order-lg-1">
                            <h4 class="mb-3 font-inter-semi-bold">Registered Office</h4>
                            <address class="c-address font-inter-medium mb-3">
                                {{ details.office.office_name }} <br>
                                <span v-html="details.office.address"/>
                            </address>
                            <p class="mb-3">
                                Phone: {{ details.office.contact_number }} <br>
                                <!--                            Toll Free: {{ details.office.toll_free_no }}-->
                            </p>
                            <p class="mb-3">
                                <!--                            Fax: {{ details.office.fax }} <br>-->
                                Email : {{ details.office.email_id }}
                            </p>
                            <p>
                                CIN:{{ details.office.cin }} <br>
                                GST IN:{{ details.office.gst_in }}
                            </p>

                        </div>

                        <div class="col-12 col-lg-4 col-md-4 order-3 order-lg-2">
                            <p v-for="(i, index) in details.directory" :key="index">
                                {{ i.name }} <span v-if="i.is_team_member">{{ i.team_member.designation }}</span><br>
                                <span v-if="i.contact_number">Ph 1: {{ i.contact_number }}</span><br>
                                <span v-if="i.mobile_number">Ph 2: {{ i.mobile_number }}</span><br>
                                Email : {{ i.email_id }}
                            </p>
                        </div>

                        <div class="col-12 col-lg-4 col-md-4 order-1 order-lg-3">
                            <div class=" w-80p-res">
                                <img v-if="details.office.location_image" class="img-fluid border-bottom"
                                     :src="details.office.location_image"
                                     alt="">
                                <img v-else class="img-fluid border-bottom"
                                     src="../assets/web/contact/img.jpg"
                                     alt="">

                            </div>
                        </div>
                    </div>

                </div>

                <div class="bg-white p-lg-5 mt-4">
                    <div class="row">

                        <div class="col-12 col-lg-4">
                            <div class="fl-y-cc h-100">
                                <div class="w-lg-50p">
                                    <img class="img-fluid border-bottom"
                                         src="../assets/web/contact/get.jpg"
                                         alt="">
                                </div>
                                <p class="text-primary fs-lg-5 mt-2  font-inter-medium">Get In Touch With Us</p>
                            </div>
                        </div>

                        <div class="col-12 col-lg-8">

                            <b-form class="row py-5" ref="contactForm" :save-url="addUrl" @success="formSuccess"
                                    v-slot="{model, loading}" :save-params="captcha">

                                <div class="col-12 col-lg-6 col-md-6">
                                    <validated-input class="mb-4" placeholder="Your Full Name*" v-model="model.name"
                                                     :rules="{required: true}" :disabled="loading"/>
                                </div>

                                <div class="col-12 col-lg-6 col-md-6">
                                    <validated-input class="mb-4 c-input-number-modify-arrow-none" type="number"
                                                     placeholder="Mobile Number*" v-model="model.mobile_number"
                                                     :rules="{required: true, min:10, max:10}" :disabled="loading"/>
                                </div>

                                <div class="col-12 col-lg-12 col-md-12">
                                    <validated-input class="mb-4" type="email" placeholder="Email ID*"
                                                     v-model="model.email_id"
                                                     :rules="{required: true}" :disabled="loading"/>
                                </div>

                                <div class="col-12 col-lg-6 col-md-6">
                                    <validated-vue-select :options="stateOptions" placeholder="State*"
                                                          class="mb-4" v-model="model.state"
                                                          @input="setDistrict(model.state)"
                                                          :rules="{required:true}" name="State"></validated-vue-select>
                                </div>

                                <div class="col-12 col-lg-6 col-md-6">
                                    <validated-vue-select :options="districtOptions" placeholder="District*"
                                                          class="mb-4" v-model="model.district"
                                                          :rules="{required:true}"
                                                          name="District"></validated-vue-select>
                                </div>

                                <div class="col-12 col-lg-12 col-md-12">
                                    <validated-input class="mb-4" placeholder="Enter Subject*" v-model="model.subject"
                                                     :rules="{required: true}" :disabled="loading"/>
                                </div>

                                <div class="col-12">
                                    <validated-textarea class="c-textarea-lg" placeholder="Your Message*"
                                                        v-model="model.message"
                                                        :rules="{required: true}" :disabled="loading"/>
                                </div>
                                <div class="col-12 fl-x-cc mb-3" v-if="captcha.captchaUrl">
                                    <mark class="text-center p-1 mb-0 fl-te-c">
                                        <img class="img-fluid" :src="captcha.captchaUrl" alt="Captcha"/>
                                        <loading-animation v-if="captchaLoading"/>
                                        <div v-else @click="loadCaptcha">
                                            <i class="fa fa-refresh"/>
                                        </div>
                                    </mark>
                                </div>
                                <div class="col-12">
                                    <validated-input placeholder="Captcha Text" v-model="model.captcha_text"
                                                     :rules="{required: true}" name="Captcha Text"
                                                     class="text-primary"></validated-input>
                                </div>

                                <div class="col-12">
                                    <div class="fl-te-c-res">
                                        <validated-checkbox label="Request a callback" v-model="model.request_call_back"
                                                            :disabled="loading"/>
                                        <btn text="Submit" class="px-5" design="basic-b" size="md" borderRadius="0"/>

                                    </div>
                                </div>
                            </b-form>

                            <modal title="Bank e Auctions India.com"
                                   class="c-modal no-close c-p-0 bs-5 notification-modal"
                                   enter-animation="animated slideInRight" exit-animation="animated slideOutRight"
                                   ref="GetAQuote"
                                   :no-close-on-backdrop="false" width="100p" header-color="primary">
                                <div>
                                </div>
                            </modal>
                        </div>
                    </div>
                </div>
            </div>
            <sub-footer></sub-footer>
        </div>
    </div>
</template>

<script>
import InnerBanner from '@components/InnerBanner';
import SubFooter from '@components/home/PremiumMembershipSection';
import axios from 'secure-axios';
import urls from '../data/urls';

export default {
    name: 'ContactUs',

    components: { SubFooter, InnerBanner },

    data () {
        return {
            countryOptions: [],
            stateOptions: [],
            allDistrictOptions: [],
            districtOptions: [],

            details: '',
            captchaError: '',
            captchaLoading: false,
            captcha: {},
            addUrl: urls.website.contactUsCreate,
            dataLoading: false
        };
    },
    beforeMount () {
        this.loadCaptcha();
    },
    mounted () {
        this.loadOptions();
        this.loadDetails();
    },

    methods: {
        async loadCaptcha () {
            this.captchaLoading = true;
            const response = await axios.get(urls.website.captcha);
            const json = response.data.data;
            this.captcha.captchaUrl = json.captcha;
            this.captcha.captcha_id = json.captcha_id;
            setTimeout(() => {
                this.captchaLoading = false;
            }, 2000);
        },
        formSuccess () {
            this.$router.go();
            this.$notify('Submitted Successfully!', 'Success',
                {
                    type: 'success'
                }
            );
        },
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.contactUs);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        },

        loadOptions () {
            const that = this;
            axios.get(urls.website.formInitialization + '?type=Text').then(function (response) {
                const json = response.data;
                that.stateOptions = [...json.state.data];
                that.allDistrictOptions = [...json.district.data];
            }).catch(function (exception) {
                console.log('exception occurred at options loading in reg form : ', exception);
            });
        },

        setDistrict (state) {
            this.districtOptions = [];
            const filterValues = this.allDistrictOptions.filter(obj => obj.state === state);
            this.districtOptions = [...filterValues];
            this.$refs.contactForm.setModelValue('district', '');
        }
    }
};
</script>

<style scoped lang="scss">
.c-address {
    //font-size: 0.9rem;
    font-size: 1rem;
    font-style: normal !important;
    line-height: 2rem;
}

.border-bottom {
    border-bottom: 6px solid var(--color-secondary);
}
</style>
